import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { CommonService } from '../services/common.service';
import { AllsportService } from '../services/allsport.service';
import { UniversitydetailsService } from '../services/universitydetails.service';
import { UniversityService } from '../services/university.service';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import { HttpClient } from '@angular/common/http';

import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-edit-universitydetails',
  templateUrl: './edit-universityDetails.component.html',
  styleUrls: ['./edit-universityDetails.component.css'],
})
export class EditUniversityDetailsComponent implements OnInit {
  authForm: FormGroup;

  data: any;
  param = { limit: 10, offset: 0 };
  val = '423243';
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required]),
  });

  ////////////////////////////////////////
  /////// Text editor

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  // tags: Tag[] = [];
  name = 'Angular 6';
  htmlContent = '';
  value = `www.wreljndkf.com/dfalkjdflkjalkdjflkasdflkjadlk`;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [['bold']],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['bold', 'italic'], ['fontSize']],
  };

  ///////////////////////////

  constructor(
    private changeDetector: ChangeDetectorRef,
    public universityService: UniversityService,
    public universitydetailsService: UniversitydetailsService,
    public commonService: CommonService,
    public sportService: AllsportService,
    private http: HttpClient,
    private formBuilder: FormBuilder
  ) {
    this.authForm = this.formBuilder.group({
      phone: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.commonService.change.subscribe((emitObject) => {
      if (emitObject.dataToEmit === 'afterresponse') {
        //  console.log('aa')
        this.changeDetector.detectChanges();
      }
    });

    window.scroll(0, 0);
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();

    const text = event.clipboardData?.getData('text/plain');

    document.execCommand(
      'insertText',
      false,
      text.normalize('NFKD').replace(/[\u0300-\u036f]/g, '')
    );
  }

  onKey(e: any) {
    console.log(e);
    this.universitydetailsService.copyuniversitydata.university_details.total_students =
      this.universitydetailsService.copyuniversitydata.university_details
        .male_students +
      this.universitydetailsService.copyuniversitydata.university_details
        .female_students +
      this.universitydetailsService.copyuniversitydata.university_details
        .other_students;
  }

  onNavigate(site: any) {
    window.open(site, '_blank');
  }

  editadminUniversitydata(data: any) {
    this.universityService.edituniversitydata(data);
  }

  editCancel() {
    this.universityService.edituniversity = false;
    this.changeDetector.detectChanges();
  }

  editdetails(data) {
    this.universitydetailsService.copyuniversitydata = JSON.parse(
      JSON.stringify(this.universitydetailsService.universityDetails)
    );

    this.universityService.edituniversity = true;
    this.changeDetector.detectChanges();
  }

  changePreferredCountries() {
    this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }
}
