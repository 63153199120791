import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { AuthService } from '../services/auth.service';
import { UniversitydetailsService } from '../services/universitydetails.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  navbarOpen = false;
  constructor(private router: Router, public common:CommonService , public authSearvice: AuthService , public universitydetailsService : UniversitydetailsService){
   
  }

  ngOnInit(): void {
  }

  ToggleNavBar11 () {
    
    let element: HTMLElement = document.getElementsByClassName( 'navbar-toggler' )[ 0 ] as HTMLElement;
    if ( element.getAttribute( 'aria-expanded' ) == 'true' ) {
        element.click();
    }
}
}
