<nav class="navbar navbar-expand-lg navbar-dark bg-default fixed-top" id="mainNav">
    <a class="navbar-brand" routerLink="/home"><img src="../assets/img/SportfolioLogo.svg" data-retina="true" alt="" width="163" height="36"></a>
    <!-- <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
    data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
    aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button> -->
    <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
    <div class="collapse navbar-collapse " id="navbarResponsive">
        <ul class="navbar-nav navbar-sidenav navbarHeight" id="exampleAccordion">
            <li class="nav-item" data-toggle="tooltip" data-placement="right" routerLinkActive="active">
                <a class="nav-link" routerLink="/home" (click)="ToggleNavBar11()">
                    <span class="nav-link-text  ">Home</span>
                </a>
            </li>
            <!-- <li class="nav-item" data-toggle="tooltip" data-placement="right" >
        <a class="nav-link nav-link-collapse collapsed" data-toggle="collapse" href="#collapseProfile"
          data-parent="#exampleAccordion">
          <span class="nav-link-text">My Profiles</span>
        </a>
        <ul class="sidenav-second-level collapse" id="collapseProfile">
          <li>
            <a routerLink="/Interested_Student_Athletes" routerLinkActive="active" [routerLinkActive]="['activeheader']">Profile</a>
          </li> 
          <li>
            <a routerLink="/university_Profile" routerLinkActive="active" [routerLinkActive]="['activeheader']">University profile</a>
          </li>
        </ul>
      </li> -->
            <li class="nav-item" data-toggle="tooltip" data-placement="right">
                <a class="nav-link" routerLink="/university_Profile" routerLinkActive="active" [routerLinkActive]="['activeheader']" (click)="ToggleNavBar11()">
                    <span class="nav-link-text ">University Profile</span>
                </a>
            </li>
            <!-- <li class="nav-item" data-toggle="tooltip" data-placement="right">
        <a class="nav-link" routerLink="/leads" routerLinkActive="active" [routerLinkActive]="['activeheader']"
          (click)="ToggleNavBar11()">
          <span class="nav-link-text">Leads</span>
        </a>
      </li> -->
            <li class="nav-item" data-toggle="tooltip" data-placement="right">
                <a class="nav-link" data-toggle="modal" data-target="#exampleModal" routerLink="/interested_Student_Athletes" routerLinkActive="active" [routerLinkActive]="['activeheader']" (click)="ToggleNavBar11()">
                    <span class="nav-link-text">Interested Student Athletes</span>
                </a>
            </li>
            <!-- <li class="nav-item" data-toggle="tooltip" data-placement="right">
        <a class="nav-link" routerLink="/" routerLinkActive="active" [routerLinkActive]="['activeheader']">
          <span class="nav-link-text">Reviews</span>
        </a>
      </li>
      <li class="nav-item" data-toggle="tooltip" data-placement="right">
        <a class="nav-link" routerLink="/" routerLinkActive="active" [routerLinkActive]="['activeheader']">
          <span class="nav-link-text">Courses</span>
        </a>
      </li>
      <li class="nav-item" data-toggle="tooltip" data-placement="right">
        <a class="nav-link" routerLink="/" routerLinkActive="active" [routerLinkActive]="['activeheader']">
          <span class="nav-link-text">settings</span>
        </a>
      </li> -->

            <li class="nav-item" data-toggle="tooltip" data-placement="right" class="logoutbtn ">
                <a class="nav-link iconcolor " data-toggle="modal" data-target="#exampleModal" (click)="authSearvice.logout()" style="cursor: pointer">
                    <i class="fa fa-fw fa-sign-out iconcolor"></i>Logout</a>
            </li>

        </ul>
        <ul class="navbar-nav sidenav-toggler">
            <!-- <li class="nav-item">
        <a class="nav-link text-center" id="sidenavToggler">
          <i class="fa fa-fw fa-angle-left"></i>
        </a>
      </li> -->
            <li class="nav-item">
                <a class="nav-link iconcolor" data-toggle="modal" data-target="#exampleModal" (click)="authSearvice.logout()">
                    <i class="fa fa-fw fa-sign-out iconcolor"></i>Logout</a>
            </li>
        </ul>

        <ul class="navbar-nav ml-auto searchblock">
            <!-- <li class="nav-item">
        <form class="form-inline my-2 my-lg-0 mr-lg-2">
          <div class="input-group">
            <input class="form-control search-top" type="text" placeholder="Search for...">
            <span class="input-group-btn">
              <button class="btn btn-primary" type="button">
                <i class="fa fa-search"></i>
              </button>
            </span>
          </div>
        </form>
      </li> -->
            <li class="nav-item">
                <a class="nav-link" data-toggle="modal" data-target="#exampleModal" style="padding: 0px !important;">
                    <div class="logobg" *ngIf="this.universitydetailsService.universityDetails.profile_picture === null">
                        <img style="height: 30px;" src="{{this.universitydetailsService.webConfigDetailsData[7].config_value}}">
                    </div>
                    <div class="logobg" *ngIf="this.universitydetailsService.universityDetails.profile_picture !== null" style="background: url({{this.universitydetailsService.universityDetails.profile_picture}});">
                        <!-- <img style="height: 30px;" src="../assets/img/MaskGroup.png"> -->
                    </div>
                </a>
            </li>
        </ul>
    </div>
</nav>